import { RouteEnum } from "defs/routes";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { NutrientType } from "defs/types";
import MyButton from "components/globals/MyButton/MyButton";
import { DeleteOutlined, FormOutlined, PlusOutlined } from "@ant-design/icons";
import { useDataContext } from "contexts//useDataContext";
import ApiHelper from "helpers/ApiHelper";
import { FlashMessageHelper } from "helpers/FlashMessageHelper";
import { Popconfirm, Table, TablePaginationConfig } from "antd";
import "./Nutrients.scss";

const NutrientsList = () => {
  const dataContext = useDataContext();
  const navigate = useNavigate();
  const itemsPerPage = 30;
  const [data, setData] = useState<NutrientType[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: itemsPerPage,
    total: 0,
    showSizeChanger: false,
    position: ["topRight", "bottomRight"],
  });
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async (newPagination?: TablePaginationConfig) => {
    newPagination = newPagination ?? pagination;
    setLoading(true);
    const response = await ApiHelper.Nutrients.paginate(
      dataContext,
      (newPagination.current - 1) * itemsPerPage,
      itemsPerPage
    );
    setData(response.data.items.sort((a, b) => a.name.localeCompare(b.name)));
    setPagination({
      ...newPagination,
      total: response.total,
    });
    setLoading(false);
  };
  const onDelete = async (id: string) => {
    const result = await ApiHelper.Nutrients.delete(id, dataContext);
    if (result.success) {
      await fetchData();
      FlashMessageHelper.success("L'élément a bien été supprimé");
    }
  };
  const columns = [
    {
      title: "Nom",
      dataIndex: "name",
      key: "name",
      // sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text: string, record: NutrientType) => (
        <>
          <div onClick={() => navigate(generatePath(RouteEnum.NUTRIENT_EDIT, { id: record.id }))}>{record.name}</div>
        </>
      ),
    },
    {
      title: "Identifiant",
      dataIndex: "identifier",
      key: "identifier",
    },
    {
      title: "Groupe",
      dataIndex: ["nutrients_group", "name"],
      key: "nutrients_group.name",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: NutrientType) => (
        <>
          <FormOutlined onClick={() => navigate(generatePath(RouteEnum.NUTRIENT_EDIT, { id: record.id }))} />
          <Popconfirm
            title={"Êtes-vous sûr ?"}
            onConfirm={() => onDelete(record.id)}
            okText='Je suis sûr.'
            cancelText='Annuler'
          >
            <DeleteOutlined style={{ color: "#a10202", marginLeft: "10px" }} />
          </Popconfirm>
        </>
      ),
    },
  ];
  return (
    <>
      <header>
        <h1>Gestion des nutriments</h1>
        <Link to={RouteEnum.NUTRIENT_CREATE}>
          <MyButton>
            <PlusOutlined style={{ marginRight: 10 }} /> Nouveau
          </MyButton>
        </Link>
      </header>
      <Table
        rowKey='id'
        loading={loading}
        columns={columns}
        dataSource={data}
        pagination={pagination}
        onChange={fetchData}
      />
    </>
  );
};
export default NutrientsList;
