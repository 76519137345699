import {
   IMCSituationEnum,
   IMGSituationEnum,
   NapCategoryEnum,
   NutrientsGroupTypeEnum,
   ProfileTypeEnum,
   RecipeDifficultyEnum,
   RecommendationUnitEnum,
   RegistrationStatus,
} from 'defs/enums';

export interface NutrientsGroupType {
   id: string;
   name: string;
   identifier: string;
   adjectiveLabel: string;
   type: NutrientsGroupTypeEnum;
   iconName: string;
   description: string;
   parentId?: string | null;
   recommendationUnit?: RecommendationUnitEnum;
   minRecommendationFormula?: string;
   maxRecommendationFormula?: string;
}

export interface NutrientType {
   id: string;
   nutrientsGroupId: string;
   nutrients_group: NutrientsGroupType;
   name: string;
   identifier: string;
   image: string;
   type: string;
   group: string;
   isAntiOxidant: boolean;
   composition: string;
   benefits: string;
   overconsumptionRisks: string;
   minRecommendationFormula: string;
   maxRecommendationFormula: string;
   recommendationUnit: RecommendationUnitEnum;
}

export enum SubscriptionLevel {
   PREMIUM_1 = 'PREMIUM_1',
 }
 export enum SubscriptionPeriod {
   MONTHLY = 'MONTHLY',
   QUATERLY = 'QUATERLY',
   YEARLY = 'YEARLY',
 }
export interface PlanSubscription {
   userId: number;
   level: SubscriptionLevel;
   period: SubscriptionPeriod;
   starts_at: string;
   ends_at: string;
 }
export interface FoodsGroupType {
   id: string;
   name: string;
   identifier: string;
   isPremium: boolean;
   hasAccess: boolean;
   iconName: string;
}

export interface FoodType {
   id: string;
   foodsGroupId: string;
   name: string;
   identifier: string;
   isPremium: boolean;
   hasAccess: boolean;
   image: string;
   benefits: string;
   foodSource: string;
   productionArea?: string;
   subgroupLabel: string;
   kcalPer100g: number;
   foodNutrients: Array<FoodNutrientSaveType>;
   foodNutrientsGroups: Array<FoodNutrientsGroupType>;
}
export interface FoodNutrientSaveType {
   id?: string;
   nutrientId: string|null;
   weightPerFood100g: number;
}

export interface FoodNutrientType {
   id: string;
   nutrientId: string;
   foodId: string;
   weightPerFood100g: number;
}
export interface FoodNutrientsGroupType {
   id: string;
   nutrientsGroupId: string;
   foodId: string;
   weightPerFood100g: number;
}

export interface RecipesCategoryType {
    id: string;
    name: string;
    identifier: string;
    imageUrl: string;
}

export interface RecipeIngredientType {
    id: string;
    recipeId: string;
    foodId: string;
    food: FoodType;
    customLabel: string;
    quantityInGrams: number;
    displayableQuantity: number;
    unitSingular: string;
    unitPlural: string;
}

export interface RecipePreparationStepType {
    id: string;
    recipeId: string;
    index: number;
    description: string;
}

export interface RecipeType {
    id: string;
    name: string;
    identifier: string;
    recipesCategoryId: string;
    imageUrl: string;
    durationInMinutes: number;
    isVegetarian: boolean;
    difficulty: RecipeDifficultyEnum;
    recommendationUnit: RecommendationUnitEnum;
    ingredients: Array<RecipeIngredientType>;
    preparationSteps: Array<RecipePreparationStepType>;
}

export interface UserSigninData {
   email: string;
   password: string;
   signinProviderData: SigninProviderData;
}

export interface SigninProviderData {
   providerId: string;
   providerType: SigninProviderType;
}
export enum SigninProviderType {
   FACEBOOK = 'FACEBOOK',
   APPLE = 'APPLE',
   GOOGLE = 'GOOGLE',
   EMAIL = 'EMAIL',
}

export interface UserType {
   id: number;
   email: string;
   signinProviderId: string;
   registrationStatus: RegistrationStatus;
   createdAt: string;
   data: any,
   stats: {
      basalMetabolicRate: number;
      averageCaloricNeed: number;
      averageCaloricRecommendation: number;
      activityCoeff: number;
      sleepingActivity: number;
      homeActivityDuration: number;
      personalTravelDuration: number;
      personalTravelCoeff: number;
      professionalTravelDuration: number;
      professionalTravelCoeff: number;
      trainingDuration: number;
      trainingDurationWithCoeff: number;
      trainingCoeff: number;
      napScore: number;
      napCategory: NapCategoryEnum;
      profileType: ProfileTypeEnum;
      IMC: number;
      IMG: number;
      IMCSituation: IMCSituationEnum;
      IMGSituation: IMGSituationEnum;
      IMCMinWeight: number;
      IMCMaxWeight: number;
      IMGMinWeight: number;
      IMGMaxWeight: number;
      minWeightRecommendation: number;
      maxWeightRecommendation: number;
   },
   plan: {
     isPremium: boolean;
     subscriptions: Array<PlanSubscription>;
   };
}