import './Leftbar.scss';

import { RouteEnum } from "defs/routes";
import { NavLink } from "react-router-dom";

import { useDataContext } from 'contexts//useDataContext';
import { FolderOutlined, LogoutOutlined, ProfileOutlined } from '@ant-design/icons';

interface LeftbarProps {
}
const Leftbar = (props: LeftbarProps) => {
   const dataContext = useDataContext();
   const routesCategories = [
      {
         header: 'Gestion des Nutriments',
         routes: [
            {
               label: 'Groupes de Nutriments',
               icon: <FolderOutlined />,
               routeName: RouteEnum.NUTRIENTS_GROUPS
            },
            {
               label: 'Nutriments',
               icon: <ProfileOutlined />,
               routeName: RouteEnum.NUTRIENTS
            },
         ],
      },
      {
         header: 'Gestion des Aliments',
         routes: [
            {
               label: 'Groupes d\'Aliments',
               icon: <FolderOutlined />,
               routeName: RouteEnum.FOODS_GROUPS
            },
            {
               label: 'Aliments',
               icon: <ProfileOutlined />,
               routeName: RouteEnum.FOODS
            },
         ],
      },
      {
         header: 'Gestion des Recettes',
         routes: [
            {
               label: 'Importer / Exporter',
               icon: <FolderOutlined />,
               routeName: RouteEnum.RECIPES_MANAGE
            },
         ],
      },
      {
         header: 'Gestion des Utilisateurs',
         routes: [
            {
               label: 'Utilisateurs',
               icon: <ProfileOutlined />,
               routeName: RouteEnum.USERS
            },
         ],
      },
   ];
   const onLogout = () => {
      localStorage.removeItem('token');
      dataContext.setData(oldData => {
         return {
            ...oldData,
            token: null,
         }
      })
   }
   return <div className={`leftbar ${dataContext.data.showLeftbar ? 'show' : 'hide'}`}>
      <div>
         {/* <div className="user-profile">
            <img src={userIcon} alt="profile"></img>
            <div className="username">Test Test</div>
         </div> */}
         <nav className="leftbar-menu">
            {routesCategories.map((routesCategory, index) => <ul key={index} className="leftbar-menu-list">
               <div className="leftbar-menu-title">{routesCategory.header}</div>
               {routesCategory.routes.map((route, index) => <li key={index} className="leftbar-menu-item">
                  <NavLink to={route.routeName} className={({ isActive }) => isActive ? 'leftbar-menu-link active-link' : 'leftbar-menu-link'}>
                     {route.icon}
                     <span>{route.label}</span>
                  </NavLink>
               </li>
               )}
            </ul>
            )}
         </nav>
      </div>
      <div className="logout" onClick={onLogout}><LogoutOutlined /> Déconnexion</div>
   </div>;
}
export default Leftbar;