import { UserType } from "defs/types";
import { useState, useEffect } from "react";

interface DataContextType {
   showLeftbar?: boolean,
   token?: string|null,
   showAppLoader?: boolean,
   user?: UserType,
}

export const defaultData: DataContextType = {
   showLeftbar: true,
};

export type UseData = {
   data: DataContextType,
   setData: React.Dispatch<React.SetStateAction<DataContextType>>
};

export const useData = (newValue: DataContextType): UseData => {
   const [data, setData] = useState<DataContextType>(defaultData);
   useEffect(() => {
      setData(newValue);
   }, [newValue]);

   return { data, setData };
};