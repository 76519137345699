import { ComponentType, useEffect, useState } from "react";

import './DefaultLayout.scss';
import Leftbar from "./Leftbar/Leftbar";
import Topbar from "./Topbar/Topbar";
import AppContent from './Content/Content';
import { useDataContext } from "contexts//useDataContext";
import { useNavigate } from "react-router-dom";
import { RouteEnum } from "defs/routes";

const DefaultLayout = <P extends object>(Component: ComponentType<P>) => {
   const DefaultLayout = (props: P) => {
      const dataContext = useDataContext();
      const navigate = useNavigate();
      useEffect(() => {
         if (dataContext.data.token === null) {
            navigate(RouteEnum.LOGIN);
         }
      }, [navigate, dataContext.data.token]);
      useEffect(() => {
         if(dataContext.data.user) {
            console.info('Données utilisateur : ', dataContext.data.user);
         }
      }, [dataContext.data.user]);
      if (typeof dataContext.data.token === 'undefined') {
         return null;
      }
      return <>
         <Topbar />
         <Leftbar />
         <AppContent>
            <Component {...props} />
         </AppContent>
      </>;
   }
   return DefaultLayout;
}
export default DefaultLayout;
