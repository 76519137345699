import { RouteEnum } from "defs/routes";
import { useDataContext } from "contexts//useDataContext";
import { ComponentType, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const DisconnectedLayout = <P extends object>(Component: ComponentType<P>) => {
   const DisconnectedLayout = (props: P) => {
      const dataContext = useDataContext();
      const navigate = useNavigate();
      useEffect(() => {
         if(dataContext.data.token) {
            navigate(RouteEnum.NUTRIENTS);
         }
      }, [dataContext.data.token]);
      return <>
         <Component {...props} />
      </>;
   }
   return DisconnectedLayout;
}
export default DisconnectedLayout;
