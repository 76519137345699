import { GoalEnum, HeightUnit, RecommendationUnitEnum, WeightUnit } from "defs/enums";

const EnumsHelper = {
   Labels: {
      ShortUnit: {
         [RecommendationUnitEnum.MICROGRAM]: 'μg',
         [RecommendationUnitEnum.MILIGRAM]: 'mg',
         [RecommendationUnitEnum.GRAM]: 'g',
         [RecommendationUnitEnum.MILLILITERS]: 'mL',
         [RecommendationUnitEnum.CENTILITERS]: 'cL',
         [RecommendationUnitEnum.LITERS]: 'L',
      },
      WeightUnit: {
         [WeightUnit.KG]: 'kg',
         [WeightUnit.LB]: 'lbs',
      },
      HeightUnit: {
         [HeightUnit.CM]: 'cm',
         [HeightUnit.IN]: 'pouce',
      },
      Goal: {
         [GoalEnum.GAIN_WEIGHT]: 'Se muscler / Prendre du poids',
         [GoalEnum.LOSE_WEIGHT]: 'Perdre du poids',
         [GoalEnum.STABILIZE_WEIGHT]: 'Stabiliser votre poids',
         [GoalEnum.ANY]: 'Aucun objectif renseigné',
      },
   },
   WeightUnit: {
      convert(value: number, initialUnit: RecommendationUnitEnum, targetUnit: RecommendationUnitEnum) {
         switch(initialUnit) {
            case RecommendationUnitEnum.MICROGRAM:
               if(targetUnit == RecommendationUnitEnum.MILIGRAM) {
                  value = value * 0.1**3;
               } else if(targetUnit == RecommendationUnitEnum.GRAM) {
                  value = value * 0.1**9;
               }
               break;
            case RecommendationUnitEnum.MILIGRAM:
               if(targetUnit == RecommendationUnitEnum.MICROGRAM) {
                  value = value * 10**3;
               } else if(targetUnit == RecommendationUnitEnum.GRAM) {
                  value = value * 0.1**3;
               }
               break;
            case RecommendationUnitEnum.GRAM:
               if(targetUnit == RecommendationUnitEnum.MICROGRAM) {
                  value = value * 10**9;
               } else if(targetUnit == RecommendationUnitEnum.MILIGRAM) {
                  value = value * 10**3;
               }
               break;
            default:
               value *= 1;
               break;
         }
         return value;
      }
   }
};

export default EnumsHelper;