import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { routes } from "./defs/routes";
import "antd/dist/reset.css";
import { DataProvider } from "./contexts/useDataContext";

import { library } from "@fortawesome/fontawesome-svg-core";
import * as Icons from "@fortawesome/free-solid-svg-icons";

const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => (Icons as any)[icon]);
library.add(...iconList);

function App() {
  return (
    <div className='App'>
      <DataProvider>
        <Router>
          <AppWrapper />
        </Router>
      </DataProvider>
    </div>
  );
}

const AppWrapper = () => {
  return (
    <Routes>
      {routes.map(({ path, component: Component, props, layout }) => {
        Component = layout === undefined ? Component : layout(Component);
        return (
          <Route
            key={path}
            path={path}
            element={<Component {...props} />}
          />
        );
      })}
    </Routes>
  );
};
export default App;
