import { useDataContext } from "contexts//useDataContext";

interface AppContentProps {
   children: any,
}

const AppContent = (props: AppContentProps) => {
   const dataContext = useDataContext();
   return <div className={`content-area ${dataContext.data.showLeftbar ? 'show-leftbar' : 'hide-leftbar'}`}>
      <div className="content">
         {props.children}
      </div>
   </div>
}

export default AppContent;