import { RouteEnum } from "defs/routes";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { FoodType } from "defs/types";
import MyButton from "components/globals/MyButton/MyButton";
import { DeleteOutlined, FormOutlined, PlusOutlined } from "@ant-design/icons";
import { useDataContext } from "contexts//useDataContext";
import ApiHelper from "helpers/ApiHelper";
import { FlashMessageHelper } from "helpers/FlashMessageHelper";
import { Popconfirm, Table, TablePaginationConfig } from "antd";
import './Foods.scss';

const FoodsList = () => {
   const dataContext = useDataContext();
   const navigate = useNavigate();
   const itemsPerPage = 50;
   const [data, setData] = useState<FoodType[]>([]);
   const [loading, setLoading] = useState(false);
   const [pagination, setPagination] = useState<TablePaginationConfig>({
      current: 1,
      pageSize: itemsPerPage,
      total: 0,
      showSizeChanger: false,
      position: ['topRight', 'bottomRight'],
   });
   useEffect(() => {
      fetchData();
   }, []);
   const fetchData = async (newPagination?: TablePaginationConfig) => {
      newPagination = newPagination ?? pagination;
      setLoading(true);
      const response = await ApiHelper.Foods.paginate(dataContext, (newPagination.current - 1) * itemsPerPage, itemsPerPage);
      setData(response.data.items);
      setPagination({
         ...newPagination,
         total: response.total,
      });
      setLoading(false);
   }
   const onDelete = async (id: string) => {
      const result = await ApiHelper.Foods.delete(id, dataContext);
      if (result.success) {
         await fetchData();
         FlashMessageHelper.success('L\'élément a bien été supprimé');
      }
   };
   const columns = [
      {
         title: 'Nom',
         dataIndex: 'name',
         key: 'name',
         render: (text: string, record: FoodType) => <>
            <div onClick={() => navigate(generatePath(RouteEnum.FOOD_EDIT, { id: record.id }))}>{record.name}</div>
         </>
      },
      {
         title: 'Identifiant',
         dataIndex: 'identifier',
         key: 'identifier',
      },
      {
         title: 'Groupe',
         dataIndex: ['foods_group', 'name'],
         key: 'foods_group.name',
      },
      {
         title: 'Sous-Groupe',
         dataIndex: 'subgroupLabel',
         key: 'subgroupLabel',
      },
      {
         title: 'Actions',
         key: 'actions',
         render: (text: string, record: FoodType) => <>
            <FormOutlined onClick={() => navigate(generatePath(RouteEnum.FOOD_EDIT, { id: record.id }))} />
            <Popconfirm
               title={"Êtes-vous sûr ?"}
               onConfirm={() => onDelete(record.id)}
               okText="Je suis sûr."
               cancelText="Annuler"
            >
               <DeleteOutlined style={{ color: '#a10202', marginLeft: '10px' }} />
            </Popconfirm>
         </>
      },
   ];
   return <>
      <header>
         <h1>Gestion des aliments</h1>
         <Link to={RouteEnum.FOOD_CREATE}>
            <MyButton><PlusOutlined style={{ marginRight: 10 }} /> Nouveau</MyButton>
         </Link>
      </header>
      <Table rowKey="id" loading={loading} columns={columns} dataSource={data} pagination={pagination} onChange={fetchData} />
   </>;
}
export default FoodsList;