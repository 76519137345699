import {
  FoodsGroupType,
  FoodType,
  NutrientsGroupType,
  NutrientType,
  SigninProviderData,
  SigninProviderType,
  UserSigninData,
} from "defs/types";
import { UseData } from "contexts//useData";
import { FlashMessageHelper } from "./FlashMessageHelper";

interface CustomFetchSettings {
  method?: string;
  data?: any;
  bonusHeaders?: any;
  dataContext?: UseData;
  token?: string;
  isFormData?: boolean;
}
const ApiHelper = {
  API_URL: process.env.REACT_APP_API_URL,
  async fetch(
    endpoint: string,
    settings: CustomFetchSettings = {
      method: "GET",
      data: null,
      bonusHeaders: {},
    }
  ) {
    const debug = false;
    const url = ApiHelper.API_URL + endpoint;
    let headers = {
      Accept: "application/json",
      ...settings.bonusHeaders,
    };
    if (!settings.isFormData) {
      headers["Content-Type"] = "application/json";
    }
    if (settings.token) {
      headers["Authorization"] = "Bearer " + settings.token;
    } else if (settings.dataContext && settings.dataContext.data.token) {
      headers["Authorization"] = "Bearer " + settings.dataContext.data.token;
    }
    try {
      let fetchSettings: RequestInit = {
        method: settings.method,
        headers,
      };
      if (settings.data) {
        if (settings.isFormData) {
          fetchSettings.body = settings.data;
        } else {
          fetchSettings.body = JSON.stringify(settings.data);
        }
      }
      if (settings.dataContext) {
        settings.dataContext.setData((oldData) => {
          return {
            ...oldData,
            showAppLoader: true,
          };
        });
      }
      let response = await fetch(url, fetchSettings);
      if (debug) {
        console.log("API REQUEST", url, headers, settings.data);
      }
      let jsonResponse = await response.json();
      if (settings.dataContext) {
        if (response.status === 401 && settings.dataContext) {
          localStorage.removeItem("token");
          settings.dataContext.setData((oldData) => {
            return {
              ...oldData,
              token: null,
            };
          });
        }
        settings.dataContext.setData((oldData) => {
          return {
            ...oldData,
            showAppLoader: false,
          };
        });
      }
      if (debug) {
        console.log("settings, ", settings);
      }
      if (!jsonResponse.success && jsonResponse.errors) {
        FlashMessageHelper.error(jsonResponse.errors[0]);
      }
      if (debug) {
        console.log("API RESPONSE JSON ::", jsonResponse);
      }
      return jsonResponse;
    } catch (error: any) {
      if (debug) {
        console.log("API FETCH error", error);
      }
      FlashMessageHelper.error(error);
    }
  },
  async uploadImage(image: any, folder: "nutrients" | "foods", dataContext: UseData) {
    const formData = new FormData();
    formData.append("image", image);
    const response = await ApiHelper.fetch("/" + folder + "/upload-image", {
      method: "POST",
      data: formData,
      dataContext,
      isFormData: true,
    });
    return response;
  },
  User: {
    async login(data: UserSigninData, dataContext: UseData) {
      const signinProviderData: SigninProviderData = {
        providerId: data.email,
        providerType: SigninProviderType.EMAIL,
      };
      data.signinProviderData = signinProviderData;
      const response = await ApiHelper.fetch("/users/login-to-backoffice", {
        method: "POST",
        data,
        dataContext,
      });
      return response;
    },
    async fetch(dataContext: UseData) {
      const response = await ApiHelper.fetch("/users", {
        dataContext,
      });
      return response;
    },
  },
  Nutrients: {
    paginate: async (dataContext: UseData, offset?: number, limit?: number) => {
      const result = await ApiHelper.Nutrients.fetch(dataContext, offset ?? 0, limit ?? 10);
      if (result.success && result.data.items && result.total) {
        return result;
      }
      return { data: { items: [], total: 0 } };
    },
    async fetchOne(dataContext: UseData, id: string) {
      const response = await ApiHelper.fetch("/nutrients/" + id, {
        dataContext,
      });
      return response;
    },
    async fetch(dataContext: UseData, offset: number, limit: number) {
      const response = await ApiHelper.fetch("/nutrients?offset=" + offset + "&limit=" + limit, {
        dataContext,
      });
      return response;
    },
    async save(data: NutrientType, dataContext: UseData) {
      const response = await ApiHelper.fetch("/nutrients", {
        method: "POST",
        data,
        dataContext,
      });
      return response;
    },
    async edit(nutrientId: string, data: NutrientType, dataContext: UseData) {
      data.image = data.image.replace(ApiHelper.API_URL, "");
      const response = await ApiHelper.fetch("/nutrients/" + nutrientId, {
        method: "POST",
        data,
        dataContext,
      });
      return response;
    },
    async delete(nutrientId: string, dataContext: UseData) {
      const response = await ApiHelper.fetch("/nutrients/" + nutrientId, {
        method: "DELETE",
        dataContext,
      });
      return response;
    },
  },
  NutrientsGroups: {
    paginate: async (dataContext: UseData, offset?: number, limit?: number) => {
      const result = await ApiHelper.NutrientsGroups.fetch(dataContext, offset ?? 0, limit ?? 10);
      if (result.success && result.data.items && result.total) {
        return result;
      }
      return { data: { items: [], total: 0 } };
    },
    async fetchOne(dataContext: UseData, id: string) {
      const response = await ApiHelper.fetch("/nutrients-groups/" + id, {
        dataContext,
      });
      return response;
    },
    async fetch(dataContext: UseData, offset: number, limit: number) {
      const response = await ApiHelper.fetch("/nutrients-groups?offset=" + offset + "&limit=" + limit, {
        dataContext,
      });
      return response;
    },
    async save(data: NutrientsGroupType, dataContext: UseData) {
      const response = await ApiHelper.fetch("/nutrients-groups", {
        method: "POST",
        data,
        dataContext,
      });
      return response;
    },
    async edit(nutrientsGroupId: string, data: NutrientsGroupType, dataContext: UseData) {
      const response = await ApiHelper.fetch("/nutrients-groups/" + nutrientsGroupId, {
        method: "POST",
        data,
        dataContext,
      });
      return response;
    },
    async delete(nutrientsGroupId: string, dataContext: UseData) {
      const response = await ApiHelper.fetch("/nutrients-groups/" + nutrientsGroupId, {
        method: "DELETE",
        dataContext,
      });
      return response;
    },
  },
  Foods: {
    paginate: async (dataContext: UseData, offset?: number, limit?: number) => {
      const result = await ApiHelper.Foods.fetch(dataContext, offset ?? 0, limit ?? 10);
      if (result.success && result.data.items && result.total) {
        return result;
      }
      return { data: { items: [], total: 0 } };
    },
    async fetchOne(dataContext: UseData, id: string) {
      const response = await ApiHelper.fetch("/foods/" + id, {
        dataContext,
      });
      return response;
    },
    async fetch(dataContext: UseData, offset: number, limit: number) {
      const response = await ApiHelper.fetch("/foods?offset=" + offset + "&limit=" + limit, {
        dataContext,
      });
      return response;
    },
    async save(data: FoodType, dataContext: UseData) {
      const response = await ApiHelper.fetch("/foods", {
        method: "POST",
        data,
        dataContext,
      });
      return response;
    },
    async edit(FoodId: string, data: FoodType, dataContext: UseData) {
      data.image = data.image.replace(ApiHelper.API_URL, "");
      const response = await ApiHelper.fetch("/foods/" + FoodId, {
        method: "POST",
        data,
        dataContext,
      });
      return response;
    },
    async delete(FoodId: string, dataContext: UseData) {
      const response = await ApiHelper.fetch("/foods/" + FoodId, {
        method: "DELETE",
        dataContext,
      });
      return response;
    },
  },
  FoodsGroups: {
    paginate: async (dataContext: UseData, offset?: number, limit?: number) => {
      const result = await ApiHelper.FoodsGroups.fetch(dataContext, offset ?? 0, limit ?? 10);
      if (result.success && result.data.items && result.total) {
        return result;
      }
      return { data: { items: [], total: 0 } };
    },
    async fetchOne(dataContext: UseData, id: string) {
      const response = await ApiHelper.fetch("/foods-groups/" + id, {
        dataContext,
      });
      return response;
    },
    async fetch(dataContext: UseData, offset: number, limit: number) {
      const response = await ApiHelper.fetch("/foods-groups?offset=" + offset + "&limit=" + limit, {
        dataContext,
      });
      return response;
    },
    async save(data: FoodsGroupType, dataContext: UseData) {
      const response = await ApiHelper.fetch("/foods-groups", {
        method: "POST",
        data,
        dataContext,
      });
      return response;
    },
    async edit(FoodsGroupId: string, data: FoodsGroupType, dataContext: UseData) {
      const response = await ApiHelper.fetch("/foods-groups/" + FoodsGroupId, {
        method: "POST",
        data,
        dataContext,
      });
      return response;
    },
    async delete(FoodsGroupId: string, dataContext: UseData) {
      const response = await ApiHelper.fetch("/foods-groups/" + FoodsGroupId, {
        method: "DELETE",
        dataContext,
      });
      return response;
    },
  },
  RecipesCategories: {
    paginate: async (dataContext: UseData, offset?: number, limit?: number) => {
      const result = await ApiHelper.RecipesCategories.fetch(dataContext, offset ?? 0, limit ?? 10);
      if (result.success && result.data.items && result.total) {
        return result;
      }
      return { data: { items: [], total: 0 } };
    },
    async fetch(dataContext: UseData, offset: number, limit: number) {
      const response = await ApiHelper.fetch("/recipes-categories?offset=" + offset + "&limit=" + limit, {
        dataContext,
      });
      return response;
    },
  },
  Recipes: {
    paginate: async (dataContext: UseData, offset?: number, limit?: number) => {
      const result = await ApiHelper.Recipes.fetch(dataContext, offset ?? 0, limit ?? 10);
      if (result.success && result.data.items && result.total) {
        return result;
      }
      return { data: { items: [], total: 0 } };
    },
    async fetch(dataContext: UseData, offset: number, limit: number) {
      const response = await ApiHelper.fetch("/recipes?offset=" + offset + "&limit=" + limit, {
        dataContext,
      });
      return response;
    },
    import: async (data: any, dataContext: UseData) => {
      const response = await ApiHelper.fetch("/recipes/import", {
        method: "POST",
        data,
        dataContext,
      });
      return response;
    },
  },
  Ingredients: {
    paginate: async (dataContext: UseData, offset?: number, limit?: number) => {
      const result = await ApiHelper.Ingredients.fetch(dataContext, offset ?? 0, limit ?? 10);
      if (result.success && result.data.items && result.total) {
        return result;
      }
      return { data: { items: [], total: 0 } };
    },
    async fetch(dataContext: UseData, offset: number, limit: number) {
      const response = await ApiHelper.fetch("/ingredients?offset=" + offset + "&limit=" + limit, {
        dataContext,
      });
      return response;
    },
  },
  PreparationSteps: {
    paginate: async (dataContext: UseData, offset?: number, limit?: number) => {
      const result = await ApiHelper.PreparationSteps.fetch(dataContext, offset ?? 0, limit ?? 10);
      if (result.success && result.data.items && result.total) {
        return result;
      }
      return { data: { items: [], total: 0 } };
    },
    async fetch(dataContext: UseData, offset: number, limit: number) {
      const response = await ApiHelper.fetch("/preparation-steps?offset=" + offset + "&limit=" + limit, {
        dataContext,
      });
      return response;
    },
  },
};

export default ApiHelper;
