import { RouteEnum } from 'defs/routes';
import { Link, useParams } from 'react-router-dom';
import { FoodType } from 'defs/types';
import Card from 'components/globals/Card/Card';
import { useEffect, useState } from 'react';
import MyButton from 'components/globals/MyButton/MyButton';
import { SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Image, Input, InputNumber, Popconfirm, Select, Switch, Upload } from 'antd';
import './EditFood.scss';
import { useDataContext } from 'contexts//useDataContext';
import { RcFile } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import ApiHelper from 'helpers/ApiHelper';
import { FlashMessageHelper } from 'helpers/FlashMessageHelper';
import TextArea from 'antd/lib/input/TextArea';
import EnumsHelper from 'helpers/EnumsHelper';
import { NutrientType } from '../../../defs/types';
import { NutrientsGroupType } from '../../../defs/types';

const EditFoodPage = () => {
   const { id } = useParams();
   const isEditMode = typeof id !== 'undefined';
   const dataContext = useDataContext();
   const [nutrients, setNutrients] = useState<NutrientType[] | null>(null);
   const [nutrientsGroups, setNutrientsGroups] = useState<NutrientsGroupType[] | null>(null);
   const [food, setFood] = useState<FoodType | null>(null);
   const [foodsGroups, setFoodsGroups] = useState<FoodType[]>([]);
   const [fileList, setFileList] = useState<UploadFile[]>([]);
   const [form] = Form.useForm();

   const formItemLayout = {
      labelCol: {
         sm: { span: 24 },
         md: { span: 8 },
      },
      wrapperCol: {
         sm: { span: 24 },
         md: { span: 16 },
      },
   };
   useEffect(() => {
      if (id) {
         fetchItem(id);
      }
   }, [id]);
   useEffect(() => {
      fetchItems();
   }, []);
   const fetchItems = async () => {
      let response = await ApiHelper.FoodsGroups.fetch(dataContext, 0, 1000);
      if (response.success && response.data.items) {
         setFoodsGroups(response.data.items);
      }
      response = await ApiHelper.Nutrients.fetch(dataContext, 0, 0);
      if (response.success && response.data.items) {
         setNutrients(response.data.items);
      }
      response = await ApiHelper.NutrientsGroups.fetch(dataContext, 0, 0);
      if (response.success && response.data.items) {
         setNutrientsGroups(response.data.items);
      }
   };
   const fetchItem = async (id: string) => {
      const response = await ApiHelper.Foods.fetchOne(dataContext, id);
      if (response.success && response.data.item) {
         const item = response.data.item;
         setFood(item);
         setFileList([{ uid: crypto.randomUUID(), name: item.name, url: item.image }]);
      }
   };
   useEffect(() => {
      if (fileList.length > 0 && fileList[0].url) {
         form.setFieldsValue({
            image: fileList[0].url,
         });
      }
   }, [form, fileList]);
   const onFinish = async (data: FoodType) => {
      if (fileList.length > 0 && !fileList[0].url) {
         const result = await ApiHelper.uploadImage(fileList[0], 'foods', dataContext);
         if (result && result.url) {
            data.image = result.url;
         }
      }
      if (isEditMode) {
         const response = await ApiHelper.Foods.edit(id, data, dataContext);
         if (response.success) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            FlashMessageHelper.success("L'aliment a bien été édité.");
         }
      } else {
         const response = await ApiHelper.Foods.save(data, dataContext);
         if (response.success) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            FlashMessageHelper.success("L'aliment a bien été créé.");
            form.resetFields();
            setFileList((fileList) => {
               return [];
            });
         }
      }
   };
   const beforeImageUpload = (file: RcFile) => {
      setFileList((fileList) => {
         return [file];
      });
      return false;
   };
   if (isEditMode && !food) {
      return null;
   }
   if (!foodsGroups) {
      return null;
   }
   return (
      <div className="edit-food-page">
         <Link to={RouteEnum.FOODS} className="btn-back">
            <span className="material-icons">chevron_left</span>
            <span>Retour</span>
         </Link>
         <header>{isEditMode ? <h1>Éditer l'aliment</h1> : <h1>Ajouter un nouvel aliment</h1>}</header>
         <Card>
            <Form
               form={form}
               labelAlign="left"
               className="edit-food-form"
               onFinish={onFinish}
               scrollToFirstError={true}
               requiredMark={false}
               initialValues={{
                  ...food,
                  foodsGroupId: food?.foodsGroupId,
               }}
               {...formItemLayout}
            >
               <Form.Item label="Groupe d'aliments" name="foodsGroupId" rules={[{ required: true, message: "Merci d'indiquer le groupe de l'aliment." }]}>
                  <Select>
                     {foodsGroups.map((foodsGroup, index) => (
                        <Select.Option key={index} value={foodsGroup.id}>
                           {foodsGroup.name}
                        </Select.Option>
                     ))}
                  </Select>
               </Form.Item>
               <Form.Item label="Nom" name="name" rules={[{ required: true, message: "Merci d'indiquer le nom de l'aliment." }]}>
                  <Input />
               </Form.Item>
               <Form.Item label="Identifiant Unique" name="identifier" rules={[{ required: true, message: "Merci d'indiquer un identifiant unique." }]}>
                  <Input />
               </Form.Item>
               <Form.Item label="Accès Premium" name="isPremium" valuePropName="checked" initialValue={false} >
                  <Switch checkedChildren="Oui" unCheckedChildren="Non" />
               </Form.Item>
               <Form.Item
                  label="Image"
                  name="image"
               // rules={[{ required: true, message: 'Merci de télécharger une image.' }]}
               >
                  <>
                     {fileList.length > 0 && fileList[0].url && <Image src={fileList[0].url ?? ''} />}
                     <Upload maxCount={1} beforeUpload={beforeImageUpload} listType="text" accept="jpeg,jpg,png" fileList={fileList} showUploadList={{ showDownloadIcon: true, showPreviewIcon: true, showRemoveIcon: false }}>
                        <Button icon={<UploadOutlined />}>Télécharger une image</Button>
                     </Upload>
                  </>
               </Form.Item>
               <Form.Item label="Bénéfices" name="benefits">
                  <TextArea showCount maxLength={3000} style={{ height: 120 }} />
               </Form.Item>
               <Form.Item label="Source alimentaire" name="foodSource" rules={[{ required: true, message: "Merci d'indiquer une source alimentaire." }]}>
                  <Input />
               </Form.Item>
               <Form.Item label="Zone de production" name="productionArea">
                  <Input />
               </Form.Item>
               <Form.Item label="Label Sous-Groupe" name="subgroupLabel" rules={[{ required: true, message: "Merci d'indiquer un label de sous-groupe." }]}>
                  <Input />
               </Form.Item>
               <Form.Item label="Quantité kcal / 100g" name="kcalPer100g" rules={[{ required: true, message: "Merci d'indiquer une quantité kcal / 100g." }]}>
                  <Input />
               </Form.Item>
               <fieldset className="food-nutrients-groups">
                  <legend>Groupes de Nutriments</legend>
                  <Form.List name="nutrientsGroups" initialValue={food?.foodNutrientsGroups ?? []}>
                     {(foodNutrientsGroupField, { add, remove }) => (
                        <>
                           {foodNutrientsGroupField.map((foodNutrientsGroupRow, index) => (
                              <div className="food-nutrient-row" key={index}>
                                 <Form.Item label="Groupe de Nutriments" name={[foodNutrientsGroupRow.name, 'nutrientsGroupId']} rules={[{ required: true, message: "Merci d'indiquer le groupe de nutriments." }]}>
                                    <Select placeholder="Nutriment">
                                       {nutrientsGroups?.map((nutrientsGroup, index) => (
                                          <Select.Option key={index} value={nutrientsGroup.id}>
                                             {nutrientsGroup.name} ({EnumsHelper.Labels.ShortUnit[nutrientsGroup.recommendationUnit]})
                                          </Select.Option>
                                       ))}
                                    </Select>
                                 </Form.Item>
                                 <Form.Item label="Quantité par 100g" name={[foodNutrientsGroupRow.name, 'weightPerFood100g']} rules={[{ required: true, message: "Merci d'indiquer la quantité par 100g." }]}>
                                    <InputNumber placeholder="Quantité par 100g" min={0} />
                                 </Form.Item>
                                 <Popconfirm title="Êtes-vous sûr de supprimer ce groupe de nutriments ?" onConfirm={() => remove(foodNutrientsGroupRow.name)} okText="Je suis sûr." cancelText="Annuler" placement="topRight">
                                    <span className="delete-action">Supprimer</span>
                                 </Popconfirm>
                              </div>
                           ))}
                           <div className="buttons">
                              <MyButton className="grey" onClick={() => add()}>
                                 Ajouter groupe de nutriments
                              </MyButton>
                           </div>
                        </>
                     )}
                  </Form.List>
               </fieldset>
               <fieldset className="food-nutrients">
                  <legend>Nutriments</legend>
                  <Form.List name="nutrients" initialValue={food?.foodNutrients ?? []}>
                     {(foodNutrientsField, { add, remove }) => (
                        <>
                           {foodNutrientsField.map((foodNutrientRow, index) => {
                              return <div className="food-nutrient-row" key={index}>
                                 <Form.Item label="Nutriment" name={[foodNutrientRow.name, 'nutrientId']} rules={[{ required: true, message: "Merci d'indiquer le nutriment." }]}>
                                    <Select placeholder="Nutriment">
                                       {nutrients?.map((nutrient, index) => {
                                          return <Select.Option key={index} value={nutrient.id}>
                                             {nutrient.name} ({EnumsHelper.Labels.ShortUnit[nutrient.recommendationUnit]})
                                          </Select.Option>
                                       }
                                       )}
                                    </Select>
                                 </Form.Item>
                                 <Form.Item label="Quantité par 100g" name={[foodNutrientRow.name, 'weightPerFood100g']} rules={[{ required: true, message: "Merci d'indiquer la quantité par 100g." }]}>
                                    <InputNumber placeholder="Quantité par 100g ()" min={0} />
                                 </Form.Item>
                                 <Popconfirm title="Êtes-vous sûr de supprimer ce nutriment ?" onConfirm={() => remove(foodNutrientRow.name)} okText="Je suis sûr." cancelText="Annuler" placement="topRight">
                                    <span className="delete-action">Supprimer</span>
                                 </Popconfirm>
                              </div>
                           }
                           )}
                           <div className="buttons">
                              <MyButton className="grey" onClick={() => add()}>
                                 Ajouter nutriment
                              </MyButton>
                           </div>
                        </>
                     )}
                  </Form.List>
               </fieldset>
               <div className="buttons">
                  <MyButton htmlType="submit">
                     <SaveOutlined /> Enregistrer
                  </MyButton>
               </div>
            </Form>
         </Card>
      </div>
   );
};
export default EditFoodPage;
