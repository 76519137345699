import { FoodsGroupType } from 'defs/types';
import { useDataContext } from 'contexts//useDataContext';
import ApiHelper from 'helpers/ApiHelper';
import { FlashMessageHelper } from 'helpers/FlashMessageHelper';
import { Form, Input, Switch } from "antd";
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import './EditFoodsGroup.scss';
import { RouteEnum } from 'defs/routes';
import Card from "components/globals/Card/Card";
import MyButton from 'components/globals/MyButton/MyButton';
import { SaveOutlined } from '@ant-design/icons';

const EditFoodsGroupPage = () => {
   const { id } = useParams();
   const isEditMode = typeof id !== 'undefined';
   const dataContext = useDataContext();
   const [foodsGroup, setFoodsGroup] = useState<FoodsGroupType|null>(null);
   useEffect(() => {
      if (id) {
         fetchItem(id);
      }
   }, [id]);
   const fetchItem = async (id: string) => {
      const response = await ApiHelper.FoodsGroups.fetchOne(dataContext, id);
      if (response.success && response.data.item) {
         const item = response.data.item;
         setFoodsGroup(item);
      }
   };
   const [form] = Form.useForm();
   const formItemLayout = {
      labelCol: {
         sm: { span: 24 },
         md: { span: 8 },
      },
      wrapperCol: {
         sm: { span: 24 },
         md: { span: 16 },
      },
   };
   if(isEditMode && !foodsGroup) {
      return null;
   }
   const onFinish = async (data: FoodsGroupType) => {
      if(isEditMode) {
         const response = await ApiHelper.FoodsGroups.edit(id, data, dataContext);
         if (response.success) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            FlashMessageHelper.success('Le groupe a bien été édité.');
         }
      } else {
         const response = await ApiHelper.FoodsGroups.save(data, dataContext);
         if (response.success) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            FlashMessageHelper.success('Le groupe a bien été créé.');
            form.resetFields();
         }
      }
   }
   return <div className="edit-foods-group-page">
      <Link to={RouteEnum.FOODS_GROUPS} className="btn-back">
         <span className="material-icons">chevron_left</span>
         <span>Retour</span>
      </Link>
      <header>
         {isEditMode ?
            <h1>Éditer le groupe d'aliments</h1>
         :
            <h1>Ajouter un nouveau groupe d'aliments</h1>
         }
      </header>
      <Card>
         <Form form={form}
            labelAlign="left"
            className="edit-foods-group-form"
            onFinish={onFinish}
            scrollToFirstError={true}
            requiredMark={false}
            initialValues={{
               ...foodsGroup,
            }}
            {...formItemLayout}>
            <Form.Item
               label="Nom"
               name="name"
               rules={[{ required: true, message: 'Merci d\'indiquer le nom.' }]}
            >
               <Input />
            </Form.Item>
            <Form.Item
               label="Identifiant Unique"
               name="identifier"
               rules={[{ required: true, message: 'Merci d\'indiquer un identifiant unique.' }]}
            >
               <Input />
            </Form.Item>
          <Form.Item label="Accès Premium" name="isPremium" initialValue={false} valuePropName="checked">
            <Switch checkedChildren="Oui" unCheckedChildren="Non" />
          </Form.Item>
            <Form.Item
               label="Icone"
               name="iconName"
               rules={[{ required: true, message: 'Merci d\'indiquer l\'icone.' }]}
            >
               <Input />
            </Form.Item>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
               <MyButton htmlType="submit"><SaveOutlined /> Enregistrer</MyButton>
            </div>
         </Form>
      </Card>

   </div>
}
export default EditFoodsGroupPage;