import DefaultLayout from "layout/default/DefaultLayout";
import LoginPage from "pages/Login/Login";
import NutrientsGroupsList from "pages/nutrients-groups/List/NutrientsGroups";
import EditNutrientsGroupPage from "pages/nutrients-groups/Edit/EditNutrientsGroup";
import NutrientsList from "pages/nutrients/List/Nutrients";
import EditNutrientPage from "pages/nutrients/Edit/EditNutrient";
import FoodsGroupsList from "pages/foods-groups/List/FoodsGroups";
import EditFoodsGroupPage from "pages/foods-groups/Edit/EditFoodsGroup";
import FoodsList from "pages/foods/List/Foods";
import EditFoodPage from "pages/foods/Edit/EditFood";
import { ComponentType, FC, PropsWithChildren } from "react";
import DisconnectedLayout from "layout/disconnected/DisconnectedLayout";
import UsersList from "pages/users/List/Users";
import ManageRecipes from 'pages/recipes/ManageRecipes';

export enum RouteEnum {
  INDEX = "/",
  LOGIN = "/connexion",
  NUTRIENTS_GROUPS = "/groupes-nutriments",
  NUTRIENTS_GROUP_CREATE = "/groupes-nutriments/editer",
  NUTRIENTS_GROUP_EDIT = "/groupes-nutriments/editer/:id",
  NUTRIENTS = "/nutriments",
  NUTRIENT_CREATE = "/nutriments/editer",
  NUTRIENT_EDIT = "/nutriments/editer/:id",
  FOODS_GROUPS = "/groupes-aliments",
  FOODS_GROUP_CREATE = "/groupes-aliments/editer",
  FOODS_GROUP_EDIT = "/groupes-aliments/editer/:id",
  FOODS = "/aliments",
  FOOD_CREATE = "/aliments/editer",
  FOODS_IMPORT = "/aliments/importer",
  FOOD_EDIT = "/aliments/editer/:id",
  RECIPES_CATEGORIES = "/categories-recettes",
  RECIPES = "/recettes",
  INGREDIENTS = "/ingredients",
  PREPARATION_STEPS = "/etapes-preparation",
  RECIPES_MANAGE = "/recettes/importer",
  USERS = "/utilisateurs",
}

export interface RouteProps {
  component: FC<PropsWithChildren<Record<string, unknown>>>;
  path: RouteEnum | string;
  props?: PropsWithChildren<Record<string, unknown>>;
  layout?: <P extends object>(Component: ComponentType<P>) => FC<P>;
}

export const routes: RouteProps[] = [
  {
    path: RouteEnum.LOGIN,
    component: LoginPage,
    layout: DisconnectedLayout,
  },
  {
    path: RouteEnum.NUTRIENTS_GROUPS,
    component: NutrientsGroupsList,
    layout: DefaultLayout,
  },
  {
    path: RouteEnum.NUTRIENTS_GROUP_CREATE,
    component: EditNutrientsGroupPage,
    layout: DefaultLayout,
  },
  {
    path: RouteEnum.NUTRIENTS_GROUP_EDIT,
    component: EditNutrientsGroupPage,
    layout: DefaultLayout,
  },
  {
    path: RouteEnum.INDEX,
    component: NutrientsList,
    layout: DefaultLayout,
  },
  {
    path: RouteEnum.NUTRIENTS,
    component: NutrientsList,
    layout: DefaultLayout,
  },
  {
    path: RouteEnum.NUTRIENT_CREATE,
    component: EditNutrientPage,
    layout: DefaultLayout,
  },
  {
    path: RouteEnum.NUTRIENT_EDIT,
    component: EditNutrientPage,
    layout: DefaultLayout,
  },
  {
    path: RouteEnum.FOODS_GROUPS,
    component: FoodsGroupsList,
    layout: DefaultLayout,
  },
  {
    path: RouteEnum.FOODS_GROUP_CREATE,
    component: EditFoodsGroupPage,
    layout: DefaultLayout,
  },
  {
    path: RouteEnum.FOODS_GROUP_EDIT,
    component: EditFoodsGroupPage,
    layout: DefaultLayout,
  },
  {
    path: RouteEnum.FOODS,
    component: FoodsList,
    layout: DefaultLayout,
  },
  {
    path: RouteEnum.FOOD_CREATE,
    component: EditFoodPage,
    layout: DefaultLayout,
  },
  {
    path: RouteEnum.FOOD_EDIT,
    component: EditFoodPage,
    layout: DefaultLayout,
  },
  {
    path: RouteEnum.RECIPES_MANAGE,
    component: ManageRecipes,
    layout: DefaultLayout,
  },
  {
    path: RouteEnum.USERS,
    component: UsersList,
    layout: DefaultLayout,
  },
];
