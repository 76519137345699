import MyButton from "components/globals/MyButton/MyButton";
import { Form, Input } from "antd";
import LoginLogo from "assets/images/login/logo.png";
import "./Login.scss";
import { UserSigninData, UserType } from "defs/types";
import ApiHelper from "helpers/ApiHelper";
import { useDataContext } from "contexts/useDataContext";
import { LoginOutlined } from "@ant-design/icons";

const LoginPage = () => {
  const dataContext = useDataContext();
  const onLoginSubmit = async (data: UserSigninData) => {
    const loginResponse = await ApiHelper.User.login(data, dataContext);
    if (loginResponse.success && loginResponse.data.token) {
      const token = loginResponse.data.token;
      const user: UserType = loginResponse.data.user;
      dataContext.setData((data: any) => {
        return {
          ...data,
          user,
          token,
        };
      });
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
    }
  };
  return (
    <div className='login-page'>
      <div className='left'>
        <img
          style={{ marginBottom: 30 }}
          alt=''
          width={120}
          src={LoginLogo}
        />
        <div className='page-title'>Se Connecter</div>

        <Form
          className='login-form'
          layout='vertical'
          requiredMark={false}
          onFinish={onLoginSubmit}
        >
          <Form.Item
            label='Addresse Email'
            name='email'
            rules={[{ required: true, message: "Merci d'indiquer votre email." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Mot de Passe'
            name='password'
            rules={[{ required: true, message: "Merci d'indiquer votre mot de passe." }]}
          >
            <Input.Password />
          </Form.Item>
          <div style={{ display: "flex", justifyContent: "center", marginTop: 30 }}>
            <MyButton htmlType='submit'>
              <LoginOutlined /> Connexion
            </MyButton>
          </div>
        </Form>
      </div>
      <div className='right'></div>
    </div>
  );
};
export default LoginPage;
