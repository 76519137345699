import { Button } from 'antd';
import { MouseEventHandler } from 'react';

import './MyButton.scss';

interface MyButtonProps {
   children: React.ReactNode,
   onClick?: MouseEventHandler;
   htmlType?: 'button' | 'reset' | 'submit';
   disabled?: boolean;
   bgColor?: string;
   className?: string;
}

function MyButton(props: MyButtonProps) {
   return (
      <Button
         disabled={typeof props.disabled !== 'undefined' ? props.disabled : false}
         type="primary"
         htmlType={`${props.htmlType ?? 'button'}`}
         className={`button cta-button ${props.className}`}
         onClick={props.onClick}
      >
         {props.children}
      </Button>
   );
}
export default MyButton;
