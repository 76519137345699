import { UseData } from "contexts/useData";

const GlobalHelper =  {
   checkFormula(formula: string, dataContext: UseData) {
      const user = dataContext.data.user;
      if(formula===null || formula.length===0) {
         return Promise.resolve();
      }
      try {
         const result = GlobalHelper.computeFormula(formula, user);
         if(isNaN(result) || typeof result !== 'number') throw "Le résultat doit être un nombre";
         return Promise.resolve();
      } catch (error) {
         return Promise.reject("La formule comporte des erreurs : " + error);
      }
   },
   computeFormula(formula: string, user: any): any {
      let result;
      eval(formula);
      return result;
   },
};

export default GlobalHelper;