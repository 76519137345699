import { RouteEnum } from "defs/routes";
import { Link, useParams } from "react-router-dom";
import { RecommendationUnitEnum } from "defs/enums";
import Card from "components/globals/Card/Card";
import MyButton from "components/globals/MyButton/MyButton";
import { InfoCircleOutlined, SaveOutlined, UploadOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { Button, Form, Image, Select, Tooltip, Upload } from "antd";
import './EditNutrient.scss';
import Checkbox from "antd/lib/checkbox/Checkbox";
import TextArea from "antd/lib/input/TextArea";
import { NutrientType, NutrientsGroupType } from 'defs/types';
import { useDataContext } from "contexts//useDataContext";
import { useEffect, useState } from "react";
import ApiHelper from "helpers/ApiHelper";
import { FlashMessageHelper } from "helpers/FlashMessageHelper";
import { RcFile } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import GlobalHelper from "helpers/GlobalHelper";
import EnumsHelper from "helpers/EnumsHelper";

const EditNutrientPage = () => {
   const { id } = useParams();
   const isEditMode = typeof id !== 'undefined';
   const dataContext = useDataContext();
   const [nutrient, setNutrient] = useState<NutrientType | null>(null);
   const [fileList, setFileList] = useState<UploadFile[]>([]);
   const [form] = Form.useForm();
   const [nutrientsGroups, setNutrientsGroups] = useState<NutrientsGroupType[]>([]);
   const formItemLayout = {
      labelCol: {
         sm: { span: 24 },
         md: { span: 8 },
      },
      wrapperCol: {
         sm: { span: 24 },
         md: { span: 16 },
      },
   };
   useEffect(() => {
      if (id) {
         fetchItem(id);
      }
   }, [id]);

   useEffect(() => {
      fetchItems();
   }, []);

   const fetchItems = async () => {
      let response = await ApiHelper.FoodsGroups.fetch(dataContext, 0, 1000);
      response = await ApiHelper.NutrientsGroups.fetch(dataContext, 0, 0);
      if (response.success && response.data.items) {
         setNutrientsGroups(response.data.items);
      }
   };
   const fetchItem = async (id: string) => {
      let response = await ApiHelper.Nutrients.fetchOne(dataContext, id);
      if (response.success && response.data.item) {
         const item = response.data.item;
         setNutrient(item);
         setFileList([{ uid: crypto.randomUUID(), name: item.name, url: item.image }]);
      }
   };
   useEffect(() => {
      if (fileList.length > 0 && fileList[0].url) {
         form.setFieldsValue({
            image: fileList[0].url
         });
      }
   }, [form, fileList])
   if (isEditMode && !nutrient) {
      return null;
   }
   const onFinish = async (data: NutrientType) => {
      if (fileList.length > 0 && !fileList[0].url) {
         const result = await ApiHelper.uploadImage(fileList[0], 'nutrients', dataContext);
         if (result && result.url) {
            data.image = result.url;
         }
      }
      if (isEditMode) {
         const response = await ApiHelper.Nutrients.edit(id, data, dataContext);
         if (response.success) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            FlashMessageHelper.success('Le nutriment a bien été édité.');
         }
      } else {
         const response = await ApiHelper.Nutrients.save(data, dataContext);
         if (response.success) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            FlashMessageHelper.success('Le nutriment a bien été créé.');
            form.resetFields();
            setFileList(fileList => {
               return []
            });
         }
      }
   }
   if (!nutrientsGroups) {
      return null;
   }
   const beforeImageUpload = (file: RcFile) => {
      setFileList(fileList => {
         return [
            file
         ]
      });
      return false;
   }
   return <div className="edit-nutrient-page">
      <Link to={RouteEnum.NUTRIENTS} className="btn-back">
         <span className="material-icons">chevron_left</span>
         <span>Retour</span>
      </Link>
      <header>
         {isEditMode ?
            <h1>Éditer le nutriment</h1>
            :
            <h1>Ajouter un nouveau nutriment</h1>
         }
      </header>
      <Card>
         <Form form={form}
            labelAlign="left"
            className="edit-nutrient-form"
            onFinish={onFinish}
            scrollToFirstError={true}
            requiredMark={false}
            initialValues={{
               isAntiOxidant: true,
               ...nutrient,
            }}
            {...formItemLayout}>
            <Form.Item
               label="Groupe de nutriments"
               name="nutrientsGroupId"
               rules={[{ required: true, message: 'Merci d\'indiquer le groupe du nutriment.' }]}
            >
               <Select>
                  {nutrientsGroups.map((nutrientsGroup, index) => <Select.Option key={index} value={nutrientsGroup.id}>
                     {nutrientsGroup.name}
                  </Select.Option>
                  )}
               </Select>
            </Form.Item>
            <Form.Item
               label="Nom"
               name="name"
               rules={[{ required: true, message: 'Merci d\'indiquer le nom du nutriment.' }]}
            >
               <Input />
            </Form.Item>
            <Form.Item
               label="Identifiant Unique"
               name="identifier"
               rules={[{ required: true, message: 'Merci d\'indiquer un identifiant unique.' }]}
            >
               <Input />
            </Form.Item>
            <Form.Item
               label="Image"
               name="image"
            // rules={[{ required: true, message: 'Merci de télécharger une image.' }]}
            >
               <>
                  {fileList.length > 0 && fileList[0].url &&
                     <Image
                        src={fileList[0].url ?? ''}
                     />
                  }
                  <Upload
                     maxCount={1}
                     beforeUpload={beforeImageUpload}
                     listType="text"
                     accept="jpeg,jpg,png"
                     fileList={fileList}
                     showUploadList={{ showDownloadIcon: true, showPreviewIcon: true, showRemoveIcon: false }}
                  >
                     <Button icon={<UploadOutlined />}>Télécharger une image</Button>
                  </Upload>
               </>
            </Form.Item>
            <Form.Item
               label="Type"
               name="type"
               rules={[{ required: true, message: 'Merci d\'indiquer le type du nutriment.' }]}
            >
               <Input />
            </Form.Item>
            <Form.Item
               label="Groupe"
               name="group"
               rules={[{ required: true, message: 'Merci d\'indiquer le groupe du nutriment.' }]}
            >
               <Input />
            </Form.Item>
            <Form.Item
               label="Est anti-oxydant"
               name="isAntiOxidant"
               rules={[{ required: true, message: 'Merci d\'indiquer si le nutriment est anti-oxydant.' }]}
               valuePropName="checked"
            >
               <Checkbox />
            </Form.Item>
            <Form.Item
               label="Composition"
               name="composition"
               rules={[{ required: false }]}
            >
               <TextArea showCount maxLength={3000} style={{ height: 120 }} />
            </Form.Item>
            <Form.Item
               label="Bénéfices"
               name="benefits"
               rules={[{ required: true, message: 'Merci d\'indiquer les bénéfices du nutriment.' }]}
            >
               <TextArea showCount maxLength={3000} style={{ height: 120 }} />
            </Form.Item>
            <Form.Item
               label="Risques de surconsommation"
               name="overconsumptionRisks"
               rules={[{ required: true, message: 'Merci d\'indiquer les risques de surconsommation du nutriment.' }]}
            >
               <TextArea showCount maxLength={3000} style={{ height: 120 }} />
            </Form.Item>
            <Form.Item
               label="Unité de recommandation"
               name="recommendationUnit"
               rules={[{ required: true, message: 'Merci d\'indiquer l\'unité de recommandation du nutriment.' }]}
            >
               <Select>
                  <Select.Option value={RecommendationUnitEnum.MICROGRAM}>{EnumsHelper.Labels.ShortUnit[RecommendationUnitEnum.MICROGRAM]}</Select.Option>
                  <Select.Option value={RecommendationUnitEnum.MILIGRAM}>{EnumsHelper.Labels.ShortUnit[RecommendationUnitEnum.MILIGRAM]}</Select.Option>
                  <Select.Option value={RecommendationUnitEnum.GRAM}>{EnumsHelper.Labels.ShortUnit[RecommendationUnitEnum.GRAM]}</Select.Option>
                  <Select.Option value={RecommendationUnitEnum.MILLILITERS}>{EnumsHelper.Labels.ShortUnit[RecommendationUnitEnum.MILLILITERS]}</Select.Option>
                  <Select.Option value={RecommendationUnitEnum.CENTILITERS}>{EnumsHelper.Labels.ShortUnit[RecommendationUnitEnum.CENTILITERS]}</Select.Option>
                  <Select.Option value={RecommendationUnitEnum.LITERS}>{EnumsHelper.Labels.ShortUnit[RecommendationUnitEnum.LITERS]}</Select.Option>
               </Select>
            </Form.Item>
            <Form.Item
               label={<Tooltip placement="top" title={"Vous devez attribuer une valeur à la variable  \"result\". Pour consulter les données disponibles, ouvrez la console (F12), et vous aurez accès à un log \"Données utilisateurs\" dans l'onglet \"Console\"."}>
                  <div><InfoCircleOutlined /> Recommandation minimum</div>
               </Tooltip>}
               name="minRecommendationFormula"
               rules={[
                  { validator: (_, formula) => { return GlobalHelper.checkFormula(formula, dataContext); } }
               ]}
            >
               <TextArea showCount maxLength={3000} style={{ height: 250 }} />
            </Form.Item>
            <Form.Item
               label={<Tooltip placement="top" title={"Vous devez attribuer une valeur à la variable  \"result\". Pour consulter les données disponibles, ouvrez la console (F12), et vous aurez accès à un log \"Données utilisateurs\" dans l'onglet \"Console\"."}>
                  <div><InfoCircleOutlined /> Recommandation maximum</div>
               </Tooltip>}
               name="maxRecommendationFormula"
               rules={[
                  { validator: (_, formula) => { return GlobalHelper.checkFormula(formula, dataContext); } }
               ]}
            >
               <TextArea showCount maxLength={3000} style={{ height: 250 }} />
            </Form.Item>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
               <MyButton htmlType="submit"><SaveOutlined /> Enregistrer</MyButton>
            </div>
         </Form>
      </Card>
   </div>;
}
export default EditNutrientPage;