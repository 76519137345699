import './Topbar.scss';
import { useDataContext } from 'contexts//useDataContext';

interface TopbarProps {
}

const Topbar = (props:TopbarProps) => {
   const dataContext = useDataContext();
   const toggleLeftbar = () => {
      dataContext.setData((data: any) => {
         return {
            ...data,
            showLeftbar: !dataContext.data.showLeftbar
         };
      })
   };
   return <nav id="topbar">
      <div className="logo">
         <span className="logo-link">
            <span>NaaForm</span>
         </span>
      </div>
      <div className="toggle-leftbar-btn">
         <button type="button" onClick={toggleLeftbar} className="nav-btn"><span className="material-icons">menu</span></button>
      </div>
      <ul className="navbar">
         {/* <button type="button" className="nav-btn"></button> */}
      </ul>
      <div className="user-actions">
         {/* <button type="button" className="nav-btn"><span className="material-icons">mail</span></button> */}
         {/* <button type="button" className="nav-btn"><img src={userIcon} className="user-img" alt="profile" width="30"></img></button> */}
      </div>
   </nav>;
}
export default Topbar;