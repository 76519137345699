export enum RecommendationUnitEnum {
   MICROGRAM = 'MICROGRAM',
   MILIGRAM = 'MILIGRAM',
   GRAM = 'GRAM',
   MILLILITERS = "MILLILITERS",
   CENTILITERS = "CENTILITERS",
   LITERS = "LITERS",
}
export enum NutrientsGroupTypeEnum {
   MICRONUTRIENTS = 'MICRONUTRIENTS',
   MACRONUTRIENTS = 'MACRONUTRIENTS',
}
export enum WeightUnit {
   KG = 'KG',
   LB = 'LB',
}
export enum HeightUnit {
   CM = 'CM',
   IN = 'IN',
}
export enum NapCategoryEnum {
   SEDENTARY = 'SEDENTARY',
   LOW_ACTIVE = 'LOW_ACTIVE',
   ACTIVE = 'ACTIVE',
   HIGH_ACTIVE = 'HIGH_ACTIVE',
}
export enum ProfileTypeEnum {
   SEDENTARY_PROFILE = 'SEDENTARY_PROFILE',
   DYNAMIC_PROFILE = 'DYNAMIC_PROFILE',
   ENDURANCE_ATHLETE = 'ENDURANCE_ATHLETE',
   ENDURANCE_ATHLETE_WITH_STRENGTH = 'ENDURANCE_ATHLETE_WITH_STRENGTH',
   STRENGTH_ATHLETE = 'STRENGTH_ATHLETE',
   STRENGTH_ATHLETE_WITH_ENDURANCE = 'STRENGTH_ATHLETE_WITH_ENDURANCE',
   MIXED_ATHLETE = 'MIXED_ATHLETE',
}
export enum ProfessionalActivityEnum {
   SEATED = "SEATED",
   STANDING = "STANDING",
   MANUAL = "MANUAL",
   PHYSICAL = "PHYSICAL",
   INTENSIVE = "INTENSIVE",
   ANY = "ANY",
}
export enum WorkedHoursPeriodEnum {
   DAILY = "DAILY",
   WEEKLY = "WEEKLY",
}
export enum RestFrequencyEnum {
   REGULAR = "REGULAR",
   DEPENDING_ON_WORKING_DAYS = "DEPENDING_ON_WORKING_DAYS",
   IRREGULAR = "IRREGULAR",
}
export interface RegularFrequencyData {
   averageSleepingDurationPerDay: number
}
export interface RegularFrequencyData {
   averageSleepingDurationPerDay: number
}
export interface DependingOnWorkingDaysFrequencyData {
   averageSleepingDurationPerWorkingDay: number,
   averageSleepingDurationPerRestDay: number,
}
export interface IrregularFrequencyData {
   minSleepingDurationPerDay: number,
   maxSleepingDurationPerDay: number,
   insufficientSleepDaysPerWeek: number,
}
export enum GenderEnum {
   MAN = "MAN",
   WOMAN = "WOMAN",
}
export enum WomanStateEnum {
   PREGNANT = "PREGNANT",
   NURSING = "NURSING",
   POSTMENOPAUSAL = "POSTMENOPAUSAL",
   ANY = "ANY",
}
export enum SportFrequencyPerWeekEnum {
   ZERO = "ZERO",
   ONE_TO_TWO = "ONE_TO_TWO",
   THREE_TO_FOUR = "THREE_TO_FOUR",
   FIVE_TO_SIX = "FIVE_TO_SIX",
   SEVEN_AND_MORE = "SEVEN_AND_MORE",
}
export enum GoalEnum {
   GAIN_WEIGHT = "GAIN_WEIGHT",
   LOSE_WEIGHT = "LOSE_WEIGHT",
   STABILIZE_WEIGHT = "STABILIZE_WEIGHT",
   ANY = "ANY",
}
export enum TravelType {
   PROFESSIONAL = 'PROFESSIONAL',
   PERSONAL = 'PERSONAL',
}
export enum TravelMeanTypeEnum {
   FEET = "FEET",
   PUBLIC_TRANSPORTATION = "PUBLIC_TRANSPORTATION",
   BIKE_OR_SCOOTER_WITH_MOTOR = "BIKE_OR_SCOOTER_WITH_MOTOR",
   BIKE_OR_SCOOTER_WITHOUT_MOTOR = "BIKE_OR_SCOOTER_WITHOUT_MOTOR",
   CAR_DRIVER = "CAR_DRIVER",
   CAR_PASSENGER = "CAR_PASSENGER",
   MOTORCYCLE = "MOTORCYCLE",
   ANY = "ANY",
}
export enum SportingActivityTypeEnum {
   ANY = "ANY",
   HIKING = "HIKING",
   GYMNASTICS = "GYMNASTICS",
   TEAM_SPORT = "TEAM_SPORT",
   STENGTH_TRAINING = "STENGTH_TRAINING",
   ENDURANCE_SPORT = "ENDURANCE_SPORT",
   RACKET_SPORT = "RACKET_SPORT",
   CYCLING = "CYCLING",
   MARTIAL_ARTS = "MARTIAL_ARTS",
   OTHER = "OTHER",
}
export enum RegistrationStatus {
   GENERAL_INFORMATION = 'GENERAL_INFORMATION',
   PROFESSIONAL_ACTIVITY = 'PROFESSIONAL_ACTIVITY',
   PROFESSIONAL_TRAVEL_MEANS = 'PROFESSIONAL_TRAVEL_MEANS',
   PERSONAL_TRAVEL_MEAN = 'PERSONAL_TRAVEL_MEAN',
   REST_DURATION = 'REST_DURATION',
   ACTIVITIES_DURATION = 'ACTIVITIES_DURATION',
   SPORTING_ACTIVTIES = 'SPORTING_ACTIVTIES',
}
export enum IMCSituationEnum {
   NOT_INTERPRETABLE = 'NOT_INTERPRETABLE',
   THINNESS = 'THINNESS',
   SLIMNESS = 'SLIMNESS',
   AVERAGE = 'AVERAGE',
   EXCESS_OF_WEIGHT = 'EXCESS_OF_WEIGHT',
   OBESITY = 'OBESITY',
   SEVERE_OBESITY = 'SEVERE_OBESITY'
}
export enum IMGSituationEnum {
   NOT_INTERPRETABLE = 'NOT_INTERPRETABLE',
   THINNESS = 'THINNESS',
   AVERAGE = 'AVERAGE',
   EXCESS_OF_FAT = 'EXCESS_OF_WEIGHT',
   OBESITY = 'OBESITY',
}

export enum RecipeDifficultyEnum {
    EASY = 'EASY',
    MEDIUM = 'MEDIUM',
    HARD = 'HARD',
}