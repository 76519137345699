import { CheckCircleOutlined, CloseCircleOutlined, ExportOutlined, ImportOutlined } from "@ant-design/icons";
import MyButton from "components/globals/MyButton/MyButton";
import { useDataContext } from "contexts/useDataContext";
import ApiHelper from "helpers/ApiHelper";
import { useEffect, useRef, useState } from "react";
import { read, utils, writeFile } from "xlsx";
import { FlashMessageHelper } from "helpers/FlashMessageHelper";
import moment from "moment";
import { RecipeIngredientType, RecipePreparationStepType, RecipeType, RecipesCategoryType } from "defs/types";
import { RecipeDifficultyEnum, RecommendationUnitEnum } from "defs/enums";
import { Spin } from 'antd';

interface RecipesSheetFormat {
   name: string;
   identifier: string;
   recipe_category_identifier: string;
   duration_in_minutes: number;
   image_url: string;
   is_vegetarian: "0" | "1";
   difficulty: RecipeDifficultyEnum;
   recommendation_unit: RecommendationUnitEnum;
}

interface RecipesCategoriesSheetFormat {
   name: string;
   identifier: string;
   image_url: string;
}

interface IngredientsSheetFormat {
   recipe_identifier: string;
   food_identifier: string;
   custom_label: string;
   displayable_quantity: number;
   quantity_in_grams: number;
   unit_singular: string;
   unit_plural: string;
}

interface PreparationStepsSheetFormat {
   recipe_identifier: string;
   index: number;
   description: string;
}

const ManageRecipes = () => {
   const dataContext = useDataContext();
   const [selectedFile, setSelectedFile] = useState(null);
   const fileInputRef = useRef(null);
   const [loadingImport, setLoadingImport] = useState(false);
   const [loadingExport, setLoadingExport] = useState(false);
   const [importSuccess, setImportSuccess] = useState(false);
   const [importFailed, setImportFailed] = useState(false);

   const fetchRecipes = async () => {
      const response = await ApiHelper.Recipes.fetch(dataContext, 0, 0);
      return response.data.items;
   };
   const fetchRecipesCategories = async () => {
      const response = await ApiHelper.RecipesCategories.fetch(dataContext, 0, 0);
      return response.data.items;
   };
   const fetchIngredients = async (offset, limit) => {
      offset = offset || 0;
      limit = limit || 0;
      const response = await ApiHelper.Ingredients.fetch(dataContext, offset, limit);
      return response.data.items;
   };
   const fetchPreparationSteps = async () => {
      const response = await ApiHelper.PreparationSteps.fetch(dataContext, 0, 0);
      return response.data.items;
   };

   const formatRecipesToSheet = (recipes: RecipeType[], recipesCategories: RecipesCategoryType[]) => {
      return recipes.map((recipe) => {
         const recipesCategoryId = recipe.recipesCategoryId;
         const recipeCategoryIdentifier = recipesCategories.find(
            (category) => category.id === recipesCategoryId
         )?.identifier;
         const data: RecipesSheetFormat = {
            name: recipe.name,
            identifier: recipe.identifier,
            recipe_category_identifier: recipeCategoryIdentifier,
            duration_in_minutes: recipe.durationInMinutes,
            image_url: recipe.imageUrl,
            is_vegetarian: recipe.isVegetarian ? "1" : "0",
            difficulty: recipe.difficulty,
            recommendation_unit: recipe.recommendationUnit,
         };
         return data;
      });
   };

   const formatRecipesCategoriesToSheet = (recipesCategories: RecipesCategoryType[]) => {
      let formattedRecipesCategories: RecipesCategoriesSheetFormat[] = [];
      for (let i = 0; i < recipesCategories.length; i++) {
         const recipeCategory = recipesCategories[i];
         const data = {
            name: recipeCategory.name,
            identifier: recipeCategory.identifier,
            image_url: recipeCategory.imageUrl,
         };
         formattedRecipesCategories.push(data);
      }
      return formattedRecipesCategories;
   };

   const formatIngredientsToSheet = (ingredients: RecipeIngredientType[], recipes: RecipeType[]) => {
      let formattedIngredients: IngredientsSheetFormat[] = [];
      for (let i = 0; i < ingredients.length; i++) {
         const ingredient = ingredients[i];
         const recipeId = ingredient.recipeId;
         const recipeIdentifier = recipes.find((recipe) => recipe.id === recipeId)?.identifier;
         const data = {
            recipe_identifier: recipeIdentifier,
            food_identifier: ingredient.food.identifier,
            custom_label: ingredient.customLabel,
            displayable_quantity: ingredient.displayableQuantity,
            quantity_in_grams: ingredient.quantityInGrams,
            unit_singular: ingredient.unitSingular,
            unit_plural: ingredient.unitPlural,
         };
         formattedIngredients.push(data);
      }
      return formattedIngredients;
   };

   const formatPreparationStepsToSheet = (preparationSteps: RecipePreparationStepType[], recipes: RecipeType[]) => {
      let formattedPreparationSteps: PreparationStepsSheetFormat[] = [];
      for (let i = 0; i < preparationSteps.length; i++) {
         const preparationStep = preparationSteps[i];
         const recipeId = preparationStep.recipeId;
         const recipeIdentifier = recipes.find((recipe) => recipe.id === recipeId)?.identifier;
         const data = {
            recipe_identifier: recipeIdentifier,
            index: preparationStep.index,
            description: preparationStep.description,
         };
         formattedPreparationSteps.push(data);
      }
      return formattedPreparationSteps;
   };

   // Fonction pour récupérer toutes les pages de données (utile pour les ingrédients car beaucoup de données )
   const fetchAllPages = async (fetchFunction) => {
      let offset = 0;
      const limit = 1000;
      let allData = [];
      let hasMoreData = true;

      while (hasMoreData) {
         const response = await fetchFunction(offset, limit);
         const data = response;
         allData = allData.concat(data);
         offset += limit;
         hasMoreData = data.length === limit;
      }

      return allData;
   };

   const onExport = async () => {
      setLoadingExport(true);
      const promises = [
         fetchRecipes(),
         fetchRecipesCategories(),
         fetchAllPages(fetchIngredients),
         fetchPreparationSteps()
      ];
      const [
         recipes,
         recipesCategories,
         ingredients,
         preparationSteps
      ] = await Promise.all(promises);

      const formattedRecipes = formatRecipesToSheet(recipes, recipesCategories);
      const formattedRecipesCategories = formatRecipesCategoriesToSheet(recipesCategories);
      const formattedIngredients = formatIngredientsToSheet(ingredients, recipes);
      const formattedPreparationSteps = formatPreparationStepsToSheet(preparationSteps, recipes);

      const workBook = utils.book_new();
      const recipesSheet = utils.json_to_sheet(formattedRecipes);
      const recipesCategoriesSheet = utils.json_to_sheet(formattedRecipesCategories);
      const ingredientsSheet = utils.json_to_sheet(formattedIngredients);
      const preparationStepsSheet = utils.json_to_sheet(formattedPreparationSteps);

      utils.book_append_sheet(workBook, recipesSheet, "recipes");
      utils.book_append_sheet(workBook, recipesCategoriesSheet, "recipesCategories");
      utils.book_append_sheet(workBook, ingredientsSheet, "ingredients");
      utils.book_append_sheet(workBook, preparationStepsSheet, "preparationSteps");

      const formattedDate = moment().format("YYYY-MM-DD-HH-mm-ss");
      writeFile(workBook, "export-recettes-" + formattedDate + ".xlsx");
      FlashMessageHelper.success("Les données ont bien été exportées");
      setLoadingExport(false);
   };

   const onImport = async () => {
      const data = await selectedFile.arrayBuffer();
      readDataFromWorkbook(data);
      setSelectedFile(null);
      fileInputRef.current.value = "";
   };

   const readDataFromWorkbook = async (data: any) => {
      setLoadingImport(true);
      const workBook = read(data);
      const currentSheetData = {};

      for (let i = 0; i < workBook.SheetNames.length; i++) {
         const sheetName = workBook.SheetNames[i];
         const sheet = workBook.Sheets[sheetName];
         const jsonData = utils.sheet_to_json(sheet);

         currentSheetData[sheetName] = jsonData;
      }
      const response = await ApiHelper.Recipes.import(currentSheetData, dataContext);
      if (response.success) {
         FlashMessageHelper.success("Les données ont bien été importées");
         setImportSuccess(response.success);
      } else {
         FlashMessageHelper.error("Une erreur est survenue lors de l'import des données");
         setImportFailed(true);
      }
      setLoadingImport(false);
   };

   useEffect(() => {
      if (selectedFile) {
         onImport();
      }
   }, [selectedFile]);

   useEffect(() => {
      setImportSuccess(false);
   }, []);

   return (
      <>
         <header>
            <h1>Gestion des recettes</h1>
            <input
               id='file-selector'
               ref={fileInputRef}
               style={{
                  display: "none",
               }}
               name='file'
               type='file'
               accept='xlsx, xls'
               multiple={false}
               onChange={(e) => setSelectedFile(e.target.files[0])}
            />
            <MyButton
               onClick={() => {
                  const fileSelector = document.getElementById("file-selector");
                  fileSelector.click();
               }}
            >
               <ImportOutlined style={{ marginRight: 10 }} /> Importer
            </MyButton>
            <MyButton onClick={onExport}>
               <ExportOutlined style={{ marginRight: 10 }} /> Exporter
            </MyButton>
         </header>
         {loadingImport &&
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
               <p>Import en cours... Cela peut prendre quelques minutes.</p>
               <Spin />
            </div>
         }
         <>
            {importSuccess &&
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
               <CheckCircleOutlined style={{ color: '#37bd54', fontSize: 25 }} />
               <p style={{ fontSize: 20 }}>Import réussi</p>
            </div>}
            {importFailed &&
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
               <CloseCircleOutlined style={{ color: 'red', fontSize: 25 }} />
               <p style={{ fontSize: 20 }}>L'import a échoué.</p>
            </div>}
         </>
         {loadingExport &&
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
               <p>Export en cours... Cela peut prendre quelques minutes.</p>
               <Spin />
            </div>
         }

      </>
   );
};
export default ManageRecipes;
