import { createContext, useContext, useEffect } from "react";
import { defaultData, useData, UseData } from "./useData";

interface Props {
   children: React.ReactNode;
}

// Generate context
export const DataContext = createContext<UseData>(undefined!);

// Generate provider
const DataProvider = ({ children }: Props) => {
   const { data, setData } = useData(defaultData);

   useEffect(() => {
      const storedToken = localStorage.getItem('token');
      const storedUser = JSON.parse(localStorage.getItem('user'));
      setData(oldData => {
         return {
            ...oldData,
            user: storedUser,
            token: storedToken,
         }
      });
   }, []);
   return (
      <DataContext.Provider value={{ data, setData }}>
         {children}
      </DataContext.Provider>
   );
};

// Custom context hook
const useDataContext = () => {
   return useContext(DataContext);
};

export { DataProvider, useDataContext };