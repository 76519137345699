import { UserType } from "defs/types";
import ApiHelper from "helpers/ApiHelper";
import { useDataContext } from "contexts//useDataContext";
import { useEffect, useState } from "react";
import { Table } from "antd";
import moment from "moment";
import EnumsHelper from "helpers/EnumsHelper";
import { GoalEnum } from "defs/enums";

const UsersList = () => {
  const dataContext = useDataContext();
  const [users, setUsers] = useState<UserType[] | null>(null);
  const [data, setData] = useState(null);
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Identifiant",
      dataIndex: "signinProviderId",
      key: "signinProviderId",
    },
    {
      title: "Premium",
      dataIndex: "isPremium",
      key: "isPremium",
      render: (isPremium) => (isPremium ? "✅" : "❌"),
    },
    {
      title: "Inscription",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => moment(date).format("DD/MM/YYYY"),
    },
    {
      title: "IMC",
      dataIndex: "imc",
      key: "imc",
      render: (imc: number) => imc.toFixed(1),
    },
    {
      title: "Poids",
      dataIndex: "weight",
      key: "weight",
      render: (weight: number) => weight + "kg",
    },
    {
      title: "Objectif",
      dataIndex: "goal",
      key: "goal",
      render: (goal: GoalEnum) => EnumsHelper.Labels.Goal[goal],
    },
    {
      title: "Naissance",
      dataIndex: "birthdate",
      key: "birthdate",
      render: (birthdate: string) => moment(birthdate).format("DD/MM/YYYY"),
    },
  ];
  useEffect(() => {
    ApiHelper.User.fetch(dataContext).then((response) => {
      if (response.success && response.users) {
        setUsers(response.users);
      }
    });
  }, []);

  useEffect(() => {
    setData((oldData) => {
      if (users === null) {
        return null;
      }
      return users.map((user) => {
        return {
          id: user.id,
          email: user.email,
          signinProviderId: user.signinProviderId,
          isPremium: user.plan.isPremium,
          createdAt: user.createdAt,
          imc: user.stats.IMC,
          weight: user.data.generalInformation.weight,
          birthdate: user.data.generalInformation.birthdate,
          goal: user.data.generalInformation.goal,
        };
      });
    });
  }, [users]);

  return (
    <>
      <header>
        <h1>Gestion des utilisateurs</h1>
      </header>
      <div className='users-list'>
        <Table
          rowKey='id'
          columns={columns}
          dataSource={data}
          loading={data == null}
        />
      </div>
    </>
  );
};
export default UsersList;
