import { RouteEnum } from "defs/routes";
import { Link, useParams } from "react-router-dom";
import { NutrientsGroupTypeEnum, RecommendationUnitEnum } from "defs/enums";
import Card from "components/globals/Card/Card";
import MyButton from "components/globals/MyButton/MyButton";
import { InfoCircleOutlined, SaveOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { Form, Select, Tooltip } from "antd";
import './EditNutrientsGroup.scss';
import TextArea from "antd/lib/input/TextArea";
import { NutrientsGroupType } from 'defs/types';
import { useEffect, useState } from "react";
import ApiHelper from "helpers/ApiHelper";
import { useDataContext } from "contexts//useDataContext";
import { FlashMessageHelper } from "helpers/FlashMessageHelper";
import GlobalHelper from "helpers/GlobalHelper";
import EnumsHelper from "helpers/EnumsHelper";

const EditNutrientsGroupPage = () => {
   const { id } = useParams();
   const isEditMode = typeof id !== 'undefined';
   const dataContext = useDataContext();
   const [nutrientsGroup, setNutrientsGroup] = useState<NutrientsGroupType|null>(null);
   const [nutrientsGroups, setNutrientsGroups] = useState<NutrientsGroupType[]>([]);
   const [parentId, setParentId] = useState<string|null>(null);
   useEffect(() => {
      if (id) {
         fetchItem(id);
      }
   }, [id]);
   useEffect(() => {
      fetchItems();
   }, []);
   const fetchItems = async () => {
      const response = await ApiHelper.NutrientsGroups.fetch(dataContext, 0, 1000);
      if(response.success && response.data.items) {
         setNutrientsGroups(response.data.items);
      }
   };
   const fetchItem = async (id: string) => {
      const response = await ApiHelper.NutrientsGroups.fetchOne(dataContext, id);
      if (response.success && response.data.item) {
         const item = response.data.item;
         setParentId(item?.parentId ?? null);
         setNutrientsGroup(item);
      }
   };
   const [form] = Form.useForm();
   const formItemLayout = {
      labelCol: {
         sm: { span: 24 },
         md: { span: 8 },
      },
      wrapperCol: {
         sm: { span: 24 },
         md: { span: 16 },
      },
   };
   if(isEditMode && !nutrientsGroup) {
      return null;
   }
   const onFinish = async (data: NutrientsGroupType) => {
      if(isEditMode) {
         const response = await ApiHelper.NutrientsGroups.edit(id, data, dataContext);
         if (response.success) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            FlashMessageHelper.success('Le groupe a bien été édité.');
         }
      } else {
         const response = await ApiHelper.NutrientsGroups.save(data, dataContext);
         if (response.success) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            FlashMessageHelper.success('Le groupe a bien été créé.');
            form.resetFields();
         }
      }
   }
   if(!nutrientsGroups) {
      return null;
   }
   return <div className="edit-nutrients-group-page">
      <Link to={RouteEnum.NUTRIENTS_GROUPS} className="btn-back">
         <span className="material-icons">chevron_left</span>
         <span>Retour</span>
      </Link>
      <header>
         {isEditMode ?
            <h1>Éditer le groupe de nutriments</h1>
         :
            <h1>Ajouter un nouveau groupe de nutriments</h1>
         }
      </header>
      <Card>
         <Form form={form}
            labelAlign="left"
            className="edit-nutrients-group-form"
            onFinish={onFinish}
            scrollToFirstError={true}
            requiredMark={false}
            initialValues={{
               ...nutrientsGroup,
               parentId: id ? parentId : null,
            }}
            {...formItemLayout}>
            <Form.Item
               label="Nom"
               name="name"
               rules={[{ required: true, message: 'Merci d\'indiquer le nom.' }]}
            >
               <Input />
            </Form.Item>
            <Form.Item
               label="Identifiant Unique"
               name="identifier"
               rules={[{ required: true, message: 'Merci d\'indiquer un identifiant unique.' }]}
            >
               <Input />
            </Form.Item>
            <Form.Item
               label="Label d'ajectif"
               name="adjectiveLabel"
               rules={[{ required: true, message: 'Merci d\'indiquer le label d\'adjectif.' }]}
            >
               <Input />
            </Form.Item>
            <Form.Item
               label="Type"
               name="type"
               rules={[{ required: true, message: 'Merci d\'indiquer le type de recommandation du nutriment.' }]}
            >
               <Select>
                  <Select.Option value={NutrientsGroupTypeEnum.MACRONUTRIENTS}>Macronutriments</Select.Option>
                  <Select.Option value={NutrientsGroupTypeEnum.MICRONUTRIENTS}>Micronutriments</Select.Option>
               </Select>
            </Form.Item>
            <Form.Item
               label="Groupe parent (optionnel)"
               name="parentId"
            >
               <Select onChange={value => setParentId(value)}>
                  <Select.Option value={null}>Sans Parent</Select.Option>
                  {nutrientsGroups.map((nutrientsGroup, index) => <Select.Option key={index} value={nutrientsGroup.id}>
                     {nutrientsGroup.name}
                  </Select.Option>
                  )}
               </Select>
            </Form.Item>
            <Form.Item
               label="Icone"
               name="iconName"
               rules={[{ required: true, message: 'Merci d\'indiquer l\'icone.' }]}
            >
               <Input />
            </Form.Item>
            <Form.Item
               label="Description"
               name="description"
            >
               <TextArea showCount maxLength={3000} style={{ height: 120 }} />
            </Form.Item>
            <Form.Item
               label="Unité de recommandation"
               name="recommendationUnit"
               rules={[{ required: true, message: 'Merci d\'indiquer l\'unité de recommandation du nutriment.' }]}
            >
               <Select>
                  <Select.Option value={RecommendationUnitEnum.MICROGRAM}>{EnumsHelper.Labels.ShortUnit[RecommendationUnitEnum.MICROGRAM]}</Select.Option>
                  <Select.Option value={RecommendationUnitEnum.MILIGRAM}>{EnumsHelper.Labels.ShortUnit[RecommendationUnitEnum.MILIGRAM]}</Select.Option>
                  <Select.Option value={RecommendationUnitEnum.GRAM}>{EnumsHelper.Labels.ShortUnit[RecommendationUnitEnum.GRAM]}</Select.Option>
                  <Select.Option value={RecommendationUnitEnum.MILLILITERS}>{EnumsHelper.Labels.ShortUnit[RecommendationUnitEnum.MILLILITERS]}</Select.Option>
                  <Select.Option value={RecommendationUnitEnum.CENTILITERS}>{EnumsHelper.Labels.ShortUnit[RecommendationUnitEnum.CENTILITERS]}</Select.Option>
                  <Select.Option value={RecommendationUnitEnum.LITERS}>{EnumsHelper.Labels.ShortUnit[RecommendationUnitEnum.LITERS]}</Select.Option>
               </Select>
            </Form.Item>
            <Form.Item
               label={<Tooltip placement="top" title={"Vous devez attribuer une valeur à la variable  \"result\". Pour consulter les données disponibles, ouvrez la console (F12), et vous aurez accès à un log \"Données utilisateurs\" dans l'onglet \"Console\"."}>
                  <div><InfoCircleOutlined /> Recommandation minimum</div>
               </Tooltip>}
               name="minRecommendationFormula"
               rules={[
                  { validator: (_, formula) => { return GlobalHelper.checkFormula(formula, dataContext); } }
               ]}
            >
               <TextArea showCount maxLength={3000} style={{ height: 250 }} />
            </Form.Item>
            <Form.Item
               label={<Tooltip placement="top" title={"Vous devez attribuer une valeur à la variable  \"result\". Pour consulter les données disponibles, ouvrez la console (F12), et vous aurez accès à un log \"Données utilisateurs\" dans l'onglet \"Console\"."}>
                  <div><InfoCircleOutlined /> Recommandation maximum</div>
               </Tooltip>}
               name="maxRecommendationFormula"
               rules={[
                  { validator: (_, formula) => { return GlobalHelper.checkFormula(formula, dataContext); } }
               ]}
            >
               <TextArea showCount maxLength={3000} style={{ height: 250 }} />
            </Form.Item>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
               <MyButton htmlType="submit"><SaveOutlined /> Enregistrer</MyButton>
            </div>
         </Form>
      </Card>
   </div>;
}
export default EditNutrientsGroupPage;